import React from "react"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { graphql, Link } from "gatsby"

const ArchiveTemplate = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  const image = posts[0].node.frontmatter.images[0].childImageSharp.original.src

  return (
    <Layout>
      <SEO image={image} />
      <div className="archive">
        {posts.map(({ node: post }) => {
          return post.frontmatter.images.map(image => {
            return (
              <div className="thumb">
                <Img
                  className="image"
                  fluid={image.childImageSharp.fluid}
                  fadeIn={false}
                />
                <Link to={post.frontmatter.path}>
                  <div className="overlay">
                    <div className="date">{post.frontmatter.date}</div>
                  </div>
                </Link>
              </div>
            )
          })
        })}
      </div>
    </Layout>
  )
}

export default ArchiveTemplate

export const archiveQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          ...ArchivePostNode
        }
      }
    }
  }
`
